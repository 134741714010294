export default defineAppConfig({
  ui: {
    primary: 'rose',
    gray: 'neutral',
    modal: {
      overlay: { 
        background: 'bg-black/50'
      }, 
      width: 'w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg rounded-lg'
    },
    card: {
      base: 'm-4',
      header: {
        base: 'flex justify-between gap-x-2 text-lg font-bold text-gray-900 items-center'
      },
      footer: { 
        base: 'flex justify-end'
      }
    },
    button: {
      default: {
        size: 'xl',
      },
      variant: {
        solid: 'disabled:opacity-50'
      },
      inline: 'justify-center'
    },
    input: {
      color: {
        white: {
          outline: 'focus:ring-gray-500 dark:focus:ring-gray-400'
        }
      }
    },
    select: {
      color: {
        white: {
          outline: 'focus:ring-gray-500 dark:focus:ring-gray-400'
        }
      }
    },
    formGroup: {
      wrapper: 'grow',
      label: {
        wrapper: 'items-start'
      },
      hint: 'truncate'
    }
  }
})